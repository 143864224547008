import React, { lazy, Suspense } from 'react';
import Loading from "./components/Common/Loading"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const Home = lazy(() => import('./pages/Home'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./pages/BlogPost'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Layout = lazy(() => import('./components/Common/Layout'));

const App = () => {
    return (
        <Router>
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="blog" element={<Blog/>} />
                        <Route path="blog/:uuid" element={<BlogPost/>}/>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    );
};

export default App;
